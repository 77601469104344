import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";

import Home from "../components/Headers/Home/Home";
import Leadership from "../components/Headers/Header";
import ContentV1 from "../components/ContentV/ContentV1/ContentV1";
import ContentV1r from "../components/ContentV/ContentV1/ContentV1r";
import VaultCard from "../components/VaultCard/VaultCard";
import MiniLogo from "../images/MiniLogo.png";
import SplashScreen from "../components/Headers/SplashScreen/SplashScreen";
import Monster from "../images/Monster_Bigger_Gun.png";
import SpaceScene from "../images/Space_scene_v3.png";
import Portal from "../images/Portal.webp";
import Watch from "../images/Watch.webp";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import AboutContentV1 from "../components/AboutIntermeta/ContentV/ContentV1/AboutContentV1";
import AboutContentV4 from "../components/AboutIntermeta/ContentV/ContentV4/AboutContentV4";
import { graphql, StaticQuery } from "gatsby";

const query = graphql`
{
  allContentfulBlogPost(limit: 4) {
    nodes {
      id
      body {
        childMarkdownRemark {
          html
        }
      }
      createdAt(formatString: "DD MMM YYYY")
      title
      thumbnail {
        publicUrl
      }
    }
  }
}
`;

const IndexPage = () => {
  const [scroll, setScroll] = useState("h-0");
  const [shouldShowNavbar, setShowNavbar] = useState(false);

  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 550) {
        setScroll("h-[550px]");
      } else {
        setScroll("h-0");
      }
    });

    if (window.scrollY > 2000) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }, []);

  return (
    <ParallaxProvider>
      <SplashScreen showNavbarCallback={setShowNavbar} />
      <div className={`${scroll}`}></div>
      <Home showNavbar={shouldShowNavbar} />

      <div
        className={`flex flex-col gap-y-[10rem] pt-64 min-h-screen w-[100%] overflow-hidden bg-IntermetaDarkPurple`}
      >
        <ContentV1
          Title="making tools"
          Content="we're acting upon our vision for what the metaverse really is. the technology we are developing aims to help innovators build for a metaverse worth entering for both developers and players.  "
          IMGSrc={Monster}
        />
        <ContentV1r
          Title="changing the game"
          Content="our technology embraces the composable creativity that the metaverse needs to be attractive to billions of people, and our first target is the gaming industry."
          IMGSrc={SpaceScene}
        />
        <ContentV1
          Title="easy come, easy go"
          Content="breaking down barriers to entry is required for the metaverse to take off. developers need tools and methods to easily enter, players need experiences they'll love."
          IMGSrc={Watch}
        />
        {/* <ContentV1r
          Title="made for metaverse developers"
          Content="we aren’t cutting corners with our work. Being designed for skillful developers, high caliber
          experiences can be made with our software. The standards we are inventing will be the
          greatest in the world for accomplishing what developers and consumers are looking for in the
          next generation of social gaming."
          IMGSrc={Watch}
        /> */}
        <section className="bg-gradient-to-tr from-IntermetaDarkPurple to-[#17184a] flex flex-col pt-20 items-center justify-center">
          <div className="w-[100%] flex items-center justify-center pb-10">
            <h2 className="text-center sm:text-left text-white text-5xl font-bold pt-5 px-8">
              Recent Updates
            </h2>
          </div>
          <div className="flex lg:flex-row !text-white flex-col w-[80%] items-center justify-center">
            <StaticQuery
              query={query}
              render={({ allContentfulBlogPost: { nodes } }) => nodes.map(node =>
                <div key={node.id} className="max-w-[30vw]">
                  <AboutContentV1
                    IMGSrc={node.thumbnail.publicUrl}
                    Date={node.createdAt}
                    Content={node.title}
                    to={`/posts/${node.id}`}
                    white
                  />
                </div>)
              }
            />
          </div>
        </section>
        <div>
          <div>
            <AboutContentV4 />
          </div>
        </div>
      </div>

      <div></div>
      <Footer />
    </ParallaxProvider>
  );
};

export default IndexPage;
