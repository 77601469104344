import React from "react"
import { Parallax } from "react-scroll-parallax"
import useMediaQuery from "../../../hooks/useMediaQuery"
import { Zoom } from "./ContentV1"

interface V1rProps {
  Title: string
  Content: string
  IMGSrc: string
}

const V1r = ({ Title, Content, IMGSrc }: V1rProps) => {

  const isDesktop = useMediaQuery("(min-width: 1024px)")

  return (
    <div className="flex flex-row justify-center items-center h-auto pb-2 lg:py-0 py-6">
      <div className="flex flex-row-reverse items-center justify-between w-[90%] lg:w-[65%] 2xl:w-[70%]">
        <Parallax
          opacity={isDesktop ? [0.7, 1] : [0.4, 1]}
          easing="easeOut"
          translateX={["200px", "-100px"]}
          className="lg:w-[75%]  overflow-hidden h-auto w-[60%]  rounded-tr-[1.5rem] md:rounded-tr-[3rem] rounded-bl-[1.5rem] md:rounded-bl-[3rem]"
        >
         <div className="w-full h-auto"> <img src={IMGSrc} className="object-cover h-full w-full"/></div>    
        </Parallax>
        <div className="w-[80%] flex flex-row justify-center lg:items-end items-center aspect-[5/4] text-white">
          <div className="w-[90%] aspect-[5/4]  flex flex-col lg:justify-start 2xl:justify-center justify-center relative lg:top-16 2xl:top-0 lg:left-20 left-8">
            <Parallax
              shouldAlwaysCompleteAnimation
              opacity={isDesktop ? [0.8, 1] : [0.4, 1]}
              easing="easeOut"
            >
              <div className="font-bold lg:text-4xl text-2xl">{Title}</div>
            </Parallax>
            <Parallax
              shouldAlwaysCompleteAnimation
              opacity={isDesktop ? [0.8, 1] : [0.4, 1]}
              easing="easeOut"
              // speed={-2}
            >
              <div className="lg:mt-2 lg:my-0 my-6 lg:text-lg text-sm lg:w-[100%]">
                {Content}
              </div>
            </Parallax>
          </div>
        </div>
      </div>
    </div>
  )
}

export default V1r