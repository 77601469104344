import React, { useEffect, useState } from "react"
import { useInViewRef, useMediaMatch, useWindowScrollPosition } from "rooks"
import { animated, useSpring } from "react-spring"
import { Parallax, useParallax } from "react-scroll-parallax"
import useMediaQuery from "../../../hooks/useMediaQuery"

interface V1Props {
  Title: string
  Content: string
  IMGSrc: string
}

const V1 = ({ Title, Content, IMGSrc }: V1Props) => {

  const isDesktop = useMediaQuery("(min-width: 1024px)")

  return (
    <div className="flex flex-row justify-center items-center h-auto mb-2 lg:py-0 py-6">
      <div className="flex flex-row items-center justify-between w-[90%] lg:w-[65%] 2xl:w-[70%]">
        <Parallax
          opacity={isDesktop ? [0.7, 1] : [0.4, 1]}
          easing="easeOut"
          translateX={["-200px", "100px"]}
          className="lg:w-[75%] overflow-hidden h-auto w-[60%]  rounded-tr-[1.5rem] md:rounded-tr-[3rem] rounded-bl-[1.5rem] md:rounded-bl-[3rem]"
        >
        <div className="w-full h-auto"> <img src={IMGSrc} className="object-cover h-full w-full"/></div>    
        </Parallax>
        <div className="w-[80%] flex flex-row justify-center  lg:items-end items-center aspect-[5/4] text-white">
          <div className="w-[90%] aspect-[5/4] flex flex-col lg:justify-start 2xl:justify-center justify-center  relative lg:top-16 2xl:top-0  lg:right-12 right-8">
            <Parallax
              shouldAlwaysCompleteAnimation
              opacity={isDesktop ? [0.8, 1] : [0.4, 1]}
              easing="easeOut"
            >
              <div className="font-bold lg:text-4xl text-2xl">{Title}</div>
            </Parallax>
            <Parallax
              shouldAlwaysCompleteAnimation
              opacity={isDesktop ? [0.8, 1] : [0.4, 1]}
              easing="easeOut"
            >
              <div className="lg:mt-2 lg:my-0 my-6 lg:text-lg text-sm lg:w-[100%]">
                {Content}
              </div>
            </Parallax>
          </div>
        </div>
      </div>
    </div>
  )
}

export default V1

export const Zoom: React.FC<any> = props => {
  const [ref, isVisible] = useInViewRef()

  const styles = useSpring({
    transform: isVisible ? "scale(1)" : "scale(0.7)",
    config: { duration: 400 },
    delay: 0,
  })

  return <animated.div ref={ref} style={styles} {...props} />
}