import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Background from "../../../images/intermetaWORLDMoon.png";
import BackgroundM from "../../../images/intermetaWORLDMoon-mobile.png";
import Arrow from "../../../images/arrow-down.png";
import { Parallax } from "react-scroll-parallax";
import { ScrollDownArrow } from "../SplashScreen/SplashScreen";

const Home = ({ showNavbar }: { showNavbar: boolean; }) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [scrolled, setScrolled] = useState(false);

  const [scroll, setScroll] = useState("fixed");

  // const [title1, setTitle1] = useState("The future")
  // const [title2, setTitle2] = useState("is")
  // const [title3, setTitle3] = useState("metable")

  useEffect(() => {
    function updateScroll() {
      if (window.scrollY > 550) {
        setScroll("relative");
        setScrolled(true);
      } else {
        setScroll("fixed");
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", updateScroll);
    return () => window.removeEventListener("scroll", updateScroll);
  }, []);

  return (
    <div>
      <Navbar hideNavbar={!showNavbar} />
      <div
        className={`${scroll} bg-home ${scrolled ? "-z-30" : "z-30"
          } h-screen flex flex-col items-center object-cover overflow-hidden`}
      >
        {!isDesktop ? (
          <img
            className="top-0 -z-20 h-screen w-screen flex justify-center items-center scale-100"
            src={BackgroundM}
            draggable={false}
          />
        ) : (
          <img
            className="top-0 overflow-x-hidden h-full w-screen -z-20 object-cover overflow-hidden"
            src={Background}
            draggable={false}
          />
        )}

        <div className="fixed text-white lg:text-6xl text-2xl sm:text-4xl top-[50vh] md:top-[35vh] text-center tracking-wide">
          <p className="leading-relaxed font-bold"><span className="font-thin">inter</span>meta</p>
          <p
            className={`leading-relaxed transition duration-500 font-light ${scrolled ? "opacity-100" : "opacity-0"
              }`}
          >
            developing baselayer <br /> metaverse technology.
          </p>
        </div>
        {isDesktop && (
          <button className="text-white fixed bottom-12 max-auto flex flex-col items-center text-2xl tracking-wide">
            view
            <ScrollDownArrow />
          </button>
        )}
      </div>
    </div>
  );
};

export default Home;
