import React, { useEffect, useLayoutEffect, useState } from "react";
import { Parallax } from "react-scroll-parallax";
import useMediaQuery from "../../../hooks/useMediaQuery";

import Overlay from "./overlay.png";
import OverlayMobile from "./overlayMobile.png";
import WhitePixel from "../../../images/whitePixel.jpg";
import Arrow from "../../../images/arrow-down.png";
import { useToggle } from "rooks";

const SplashScreen = ({
  showNavbarCallback,
}: {
  showNavbarCallback: (shouldNavbarBeShown: boolean) => void;
}) => {
  //   const { ref } = useParallax<HTMLDivElement>({scale: [-100, 100], easing: "easeOutQuad",})

  // Add a listener to make the component disappear on scroll down
  const [visible, setVisible] = useState<boolean>(true);

  const [viewArrow, setViewArrow] = useState<boolean>(true);

  const isDesktop = useMediaQuery("(min-width: 800px)");
  const shouldShowArrow = useMediaQuery("(min-width: 1024px)");
  const visibilityBreakpoint = isDesktop ? 550 : 150;

  useEffect(() => {
    const timeout = setTimeout(() => {
      showNavbarCallback(!visible);
    }, 100);

    return () => clearTimeout(timeout);
  }, [visible]);

  useLayoutEffect(() => {
    function updateVisible() {
      if (window.scrollY < 2) {
        setViewArrow(true);
      } else {
        setViewArrow(false);
      }

      setVisible(window.scrollY < visibilityBreakpoint);
    }

    window.addEventListener("scroll", updateVisible);
    return () => window.removeEventListener("scroll", updateVisible);
  }, []);

  return (
    <>
      <div
        className={`transition duration-150  fixed ${visible ? "z-[60]" : "-z-[50]"
          } w-screen h-screen flex justify-center items-center`}
      >
        <div className="flex justify-center items-center">
          <Parallax
            shouldAlwaysCompleteAnimation
            scale={[1, 10]}
            easing="easeOutBack"
            rootMargin={{ top: 200, right: 100, bottom: 100, left: 100 }}
            className="flex flex-row justify-center items-center"
          >
            <div className="w-screen h-screen relative z-10 flex flex-row items-stretch justify-center">
              <img src={WhitePixel} alt="" className="w-full" />
              <img
                draggable={false}
                className={visible ? "visible" : "invisible"}
                src={isDesktop ? Overlay : OverlayMobile}
                alt="Intermeta cover image showing planets and sky"
              />
              <img src={WhitePixel} alt="" className="w-full" />
            </div>
          </Parallax>

          {isDesktop && (
            <button
              className={`${viewArrow && shouldShowArrow ? "text-black" : "hidden"}
              absolute bottom-[3rem] max-auto z-50  flex flex-col items-center text-2xl tracking-wide`}
            >
              view
              <ScrollDownArrow invert />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SplashScreen;

export const ScrollDownArrow: React.FC<{ invert?: boolean; }> = ({
  invert = false,
}) => {
  const [isDown, toggle] = useToggle(false);
  useEffect(() => {
    const interval = setInterval(toggle, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <img
      src={Arrow}
      className={`w-7 mt-2 ${invert && "invert"} 
      transition-all ${isDown ? "duration-75" : "duration-500"}`}
      style={{ marginTop: isDown ? "1rem" : "0", marginBottom: isDown ? "0" : "1rem" }}
    />
  );
};
